<template>
  <v-row no-gutters v-if="getUser"
         class="text-center"
         justify="center"
         align="center">
    <v-col :cols="12">
      <user-profile-card/>
    </v-col>
    <v-col v-if="!getUser.emailVerified" :cols="12">
      <not-verified-user/>
    </v-col>
    <v-col v-else :cols="12">
      <v-row dense>
        <v-col :cols="12" class="title">
          Saved Data:
          <v-btn icon x-small color="warning" v-if="mode" @click="mode=null">
            <app-icon icon="cancel"/>
          </v-btn>
        </v-col>
        <v-col :cols="12">
          <v-row v-if="!mode" class="text-center" justify="center">
            <v-col class="mt-2 py-8 px-2"
                   v-for="data in user_data"
                   :key="data.name">
              <v-btn rounded outlined
                     :class="`${$vuetify.breakpoint.lgAndUp ? 'title' : 'body-1'} font-weight-black text-capitalize elevation-20`"
                     @click="mode=data.name">
                {{ data.name }}
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <app-container height="70vh" v-if="mode">
          <user-exercises v-if="mode='exercises'"/>
        </app-container>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import UserExercises  from 'src/sections/UserExercises'

export default {
  name: 'Profile',

  head: {
    title: function() {
      return {
        inner: 'Profile',
      }
    },
    meta:  [
      {
        name:    'user profile',
        content: 'the user profile',
        id:      'desc',
      },
    ],
  },

  components: {
    UserExercises,
    UserProfileCard: () => import(/* webpackChunkName: "auth" */ 'framework/ui/sections/UserProfileCard'),
    AppContainer:    () => import(/* webpackChunkName: "components" */ 'framework/ui/components/AppContainer'),
    NotVerifiedUser: () => import(/* webpackChunkName: "auth" */ 'framework/ui/sections/NotVerifiedUser'),
  },

  created() {
    if( !this.getIsLoggedIn) {
      this.$router.push('login')
    }
  },

  data() {
    return {
      user_data: [
        {
          name: 'Exercises',
        },
      ],
      mode:      null,
    }
  },

  computed: {
    ...mapGetters('user', ['getUser', 'getIsLoggedIn']),
  },
}
</script>
