<template>
  <v-card class="transparent text-left" max-width="350">
    <v-card-title>
      <span v-text="reasonForUser.title"/>
    </v-card-title>
    <v-card-text>
      <span v-html="reasonForUser.text"/>
    </v-card-text>
  </v-card>
</template>

<script>
  import appConfig from 'app-config/app.json'

  export default {
    name: 'ReasonForUser',
    data() {
      return {
        reasonForUser: appConfig.reasonForUser,
      }
    },
  }
</script>
