<template>
  <div class="text-center">
    <app-spinner v-if="!doneProcessing"/>
    <h4 v-else>
      {{ msg }}
    </h4>
    <v-btn block to="/" class="my-10">Go to home page</v-btn>
  </div>
</template>

<script>
  import { handleRecoverEmail, handleResetPassword, handleVerifyEmail } from 'framework/environment/auth'

  export default {
    name:       'Auth',
    components: { AppSpinner: () => import(/* webpackChunkName: "components" */ 'framework/ui/components/AppSpinner') },
    mounted() {
      setTimeout(async () => {
        const mode       = getParameterByName('mode')
        const actionCode = getParameterByName('oobCode')

        switch (mode) {
          case 'resetPassword':
            // Display reset password handler and UI.
            this.msg = await handleResetPassword(actionCode)
            break
          case 'recoverEmail':
            // Display email recovery handler and UI.
            this.msg = await handleRecoverEmail(actionCode)
            break
          case 'verifyEmail':
            this.msg = await handleVerifyEmail(actionCode)
            break
          default:
            this.msg =
              'An Error might have accured, please restart the app, if it didn\'t work try again later.'
            break
        }
        this.continueUrl    = getParameterByName('continueUrl')
        this.doneProcessing = true
      }, 4000)
    },

    data() {
      return {
        doneProcessing: false,
        continueUrl:    null,
        msg:            null,
      }
    },
  }

  function getParameterByName(name) {
    const url_str = window.location.href
    const url     = new URL(url_str)
    return url.searchParams.get(name)
  }
</script>
