<template>
  <div class="text-center">
    <div v-if="shouldShowLoginMenu">
      <div class="d-flex justify-center">
        <reason-for-user/>
      </div>
      <div v-show="!isNetworkOnline" data-test="offline-instruction"/>
      <section id="firebaseui-auth-container"/>
      <!--      <user-form @action="action" action-name="Login">-->
      <!--        <template #title>-->
      <!--          Login-->
      <!--        </template>-->
      <!--        <template #bottom>-->
      <!--          <v-btn block color="secondary" to="/sign-up">Sign Up</v-btn>-->
      <!--        </template>-->
      <!--      </user-form>-->
    </div>
    <div v-else class="py-5">
      <div v-if="isNetworkOnline" class="title">
        <div class="py-5">
          Already logged in silly!
        </div>
        <v-btn rounded @click="logoutDialog=true">
          Logout
        </v-btn>
        <v-dialog scrollable v-model="logoutDialog" max-width="250">
          <logout @close="logoutDialog=false"/>
        </v-dialog>
      </div>
      <span v-else class="title">Please check your connection, login feature is not available offline.</span>
    </div>
    <v-dialog v-model="loginDialog" max-width="300">
      <app-dialog @close="$router.push('/')">
        <template #title>Success! 👌</template>
        <div class="primary">
          <div class="pa-5">
            You have successfully logged in!<br>Now lets JAM 🎸🎤
          </div>
          <v-btn block small color="error" to="/">
            Let's Do it
          </v-btn>
        </div>
      </app-dialog>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { url }                    from 'app-config/app.json'
import firebase                   from 'firebase/app'
import * as firebaseui            from 'firebaseui'
import 'firebase/auth'
import 'firebaseui/dist/firebaseui.css'
import { sendVerificationEmail }  from 'framework/environment/auth'

export default {
  name:       'Login',
  components: {
    // UserForm:      () => import(/* webpackChunkName: "auth" */ 'framework/ui/components/UserForm'),
    Logout:        () => import(/* webpackChunkName: "auth" */ 'framework/ui/sections/Logout'),
    ReasonForUser: () => import(/* webpackChunkName: "auth" */ 'framework/ui/sections/ReasonForUser'),
    AppDialog:     () => import(/* webpackChunkName: "app-main" */ 'framework/ui/components/AppDialog')
  },

  mounted() {
    this.setupLoginUi()
  },

  data() {
    return {
      logoutDialog: false,
      loginDialog:  false
    }
  },

  computed: {
    ...mapGetters('user', ['getIsLoggedIn']),
    ...mapGetters('environment', ['isNetworkOnline']),
    shouldShowLoginMenu: function() {
      return !this.getIsLoggedIn && this.isNetworkOnline
    }
  },

  methods: {
    // ...mapActions('user', ['logout', 'login']),

    setupLoginUi() {
      if(this.getIsLoggedIn) {
        return
      }
      let ui = firebaseui.auth.AuthUI.getInstance()
      if( !ui) {
        ui = new firebaseui.auth.AuthUI(firebase.auth())
      }
      const uiConfig = {
        tosUrl:           `${ url }terms-of-use`,
        privacyPolicyUrl: `${ url }privacy-policy`,
        signInSuccessUrl: `${ url }`,

        signInOptions: [
          {
            provider:           firebase.auth.EmailAuthProvider.PROVIDER_ID,
            requireDisplayName: true,
            emailLinkSignIn:    function() {
              sendVerificationEmail()
              return {
                url:             `${ url }`,
                // Always true for email link sign-in.
                handleCodeInApp: true,
                android:         {
                  packageName:    'com.musitelligence.vocalcoach',
                  installApp:     true,
                  minimumVersion: '12'
                }
              }
            }
          },
          firebase.auth.GoogleAuthProvider.PROVIDER_ID
          // firebase.auth.TwitterAuthProvider.PROVIDER_ID,
          // firebase.auth.FacebookAuthProvider.PROVIDER_ID
        ]
      }
      ui.start('#firebaseui-auth-container', uiConfig)
    },

    // async login() {
    //   const provider = new firebase.auth.GoogleAuthProvider()
    //   this.SET_USER(undefined)
    //
    //   try {
    //     // Firebase signin with popup is faster than redirect
    //     // but we can't use it on mobile because it's not well supported
    //     // when app is running as standalone on ios & android
    //     this.isDesktop
    //     ? await firebase.auth().signInWithPopup(provider)
    //     : await firebase.auth().signInWithRedirect(provider)
    //   } catch (err) {
    //     this.SET_USER(null)
    //   }
    // },

    // async action(email, password) {
    //   const loginAttempt = await firebase.auth().signInWithEmailAndPassword(email, password).catch(function(error) {
    //     const errorCode    = error.code
    //     const errorMessage = error.message
    //     // [START_EXCLUDE]
    //     if(errorCode === 'auth/wrong-password') {
    //       alert('Wrong password.')
    //     } else {
    //       alert(errorMessage)
    //     }
    //     // console.log(error)
    //   })
    //
    //   if(loginAttempt) {
    //     this.login(await firebase.auth().currentUser)
    //     this.loginDialog = true
    //   }
    // }

  }
}
</script>

<style lang="scss">
 .v-application ul{
   padding-left: 0 !important;
 }
</style>
