import { Draw, Frequency, Time, Transport } from 'tone'
import audioManager                         from 'framework/models/AudioManager'
import store                                from 'src/store'
import { intervalsToNotes, mapToDecibels }  from 'framework/utilities/Utilities'
import { playNotes }                        from 'framework/helpers/audio-playing/play-notes'
import { DRUMS_PLAYER, APP_PLAYER_NAMES }   from 'framework/resources/framework-constants'

export function playIntervalsCallback({ time, note, intervals, dur, playTracker }) {
  if(note && intervals && dur) {
    const notes      = intervalsToNotes(note, intervals)
    const instrument = store.getters['players/getMainPlayerInstrument']
    audioManager[instrument].play({ notes, dur, time })
    if(playTracker) {
      notes.forEach(note => playTracker.push({
        midi: Frequency(note).toMidi(),
        time: Transport.seconds
      }))
    }
    Draw.schedule(() => {
      store.dispatch('piano-state/autoPianoKeysPress', {
        keys: notes,
        dur
      })
    }, time)
  }
}

export function playMidiNoteCallback({ note, time, startTime, endTime, velocity, isDrum, instrument }) {
  if(isDrum) {
    velocity = velocity && mapToDecibels(velocity)
    audioManager[DRUMS_PLAYER].play(note, time, velocity)
  } else {
    const playerID = APP_PLAYER_NAMES[instrument]
    playNotes({
      time,
      notes:      [Frequency(note, 'midi').toNote()],
      dur:        Time(endTime - startTime).toNotation(),
      velocity:   velocity && velocity / 100,
      instrument: store.getters['players/getPlayerInstrument'](playerID)
    })
  }
}

export function stopPartCallback({ stop }) {
  if(stop) {
    store.dispatch('transport/stopPlayback')
  }
}

export function visualizeSingleNoteCallback({ note, startTime, endTime }) {
  const keys = [Frequency(note, 'midi').toNote()]
  const dur  = Time(endTime - startTime).toNotation()
  store.dispatch('piano-state/autoPianoKeysPress', {
    keys,
    dur
  })
}

export function visualizeMainPlayer({ note, startTime, endTime, instrument }) {
  const playerID = APP_PLAYER_NAMES[instrument]
  if(playerID === store.getters['players/getMainPlayerID']) {
    const keys = [Frequency(note, 'midi').toNote()]
    const dur  = Time(endTime - startTime).toNotation()
    store.dispatch('piano-state/autoPianoKeysPress', {
      keys,
      dur
    })
  }
}

function updateCaptureUserMediaState({ captureUserMedia }) {
  if(captureUserMedia !== undefined) {
    store.dispatch('input/setShouldCaptureUserMedia', captureUserMedia)
  }
}

function trackPlayedNotes({ playTracker, note }) {
  playTracker.push({
    midi: Frequency(note).toMidi(),
    time: Transport.seconds
  })
}

export const partCallbacks = {
  updateCaptureUserMediaState,
  playIntervalsCallback,
  playMidiNoteCallback,
  stopPartCallback,
  visualizeSingleNoteCallback,
  visualizeMainPlayer,
  trackPlayedNotes
}
