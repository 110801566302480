/**
 * A model to handle multiple tone parts
 */
export class PartsHandler {
  constructor() {
    this.parts       = {}
    this.activeParts = []
  }

  addPart(id, part) {
    const oldPart = this.parts[id]
    if (oldPart) {
      oldPart.cancel()
      oldPart.dispose()
    }
    this.parts[id] = part
  }

  activatePart(id) {
    this.activeParts.push(id)
  }

  deactivatePart(id) {
    const index = this.activeParts.indexOf(id)
    this.activeParts.splice(index, 1)
  }

  startActiveParts() {
    this.activeParts.forEach(id => this.startPart(id))
  }

  getPart(id) {
    const part = this.parts[id]
    if (!part) {
      throw new Error(`No part with id ${id}`)
    }
    return part
  }

  startPart(id, time = 0) {
    this.getPart(id).start(time)
  }

  stopPart(id) {
    this.getPart(id).stop()
  }

  togglePart(id) {
    this.getPart(id).state === 'started' ? this.stopPart(id) : this.startPart(id)
  }

  disposePart(id) {
    const part = this.parts[id]
    if (part) {
      part.dispose()
      this.parts[id] = null
    }
  }

  disposeAll() {
    Object.keys(this.parts).forEach(id => this.disposePart(id))
  }
}

export const partsHandler = new PartsHandler()
