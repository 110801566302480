<template>
  <v-expansion-panels>
    <v-expansion-panel class="transparent">
      <v-expansion-panel-header>
        <span :class="$vuetify.breakpoint.smAndDown ? 'title' : 'body-1'">
          &nbsp;{{ getUser.username }} [Click for info]
        </span>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="text-capitalize">
        <span class="pa-3">
          Name: {{ getUser.name }}<br>
          Email: {{ getUser.email }}<br>
          Country: {{ getUser.country }}<br>
          City/State: {{ getUser.city }}<br>
          Verified: {{ getUser.emailVerified ? 'Yes' : 'No' }}
        </span>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'GetUserProfileCard',

    computed: {
      ...mapGetters('user', ['getUser']),
    },
  }
</script>
