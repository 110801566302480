import { Draw, Loop, Part }                            from 'tone'
import {
  calculateNotesDistance, transposeNote
}                                                      from 'framework/helpers/note-art-helpers'
import audioManager                                    from 'framework/models/AudioManager'
import { partsHandler }                                from 'framework/models/PartsHandler'
import store                                           from 'src/store'
import { exerciseToTonePartEvents, intervalsToEvents } from 'framework/helpers/audio-playing/exercise-scheduling'
import { partCallbacks }                               from 'framework/helpers/audio-playing/part-callbacks'
import {
  PLAY_INTERVALS_CALLBACK, STOP_PART_CALLBACK, UPDATE_CAPTURE_USER_MEDIA_STATE
}                                                      from 'framework/types/part-callback-types'
import { playTracker }                                 from 'misc/globals'

/**
 * Adds a stop transport event to a play events object.
 * @param {Array} events
 */
export function concatStopTransportEvent(events) {
  const overallTime = { ...events[events.length - 1].time }
  ++overallTime['4n']
  events.push({
    time: { ...overallTime },
    stop: true
  })
}

export function schedulePitchPractice({ startNote, endNote, isInteractive = false }) {
  const timeInterval = isInteractive ? '1n' : '2n'
  store.dispatch('input/setShouldCaptureUserMedia', isInteractive)
  const pitchExerciseLoop = new Loop((time) => {
    const distance   = calculateNotesDistance(startNote, endNote)
    const interval   = Math.floor(Math.random() * distance)
    let note         = transposeNote(startNote, interval)
    const instrument = store.getters['players/getMainPlayerInstrument']
    const payload    = {
      notes: [note],
      dur:   timeInterval,
      time
    }
    audioManager[instrument].play(payload)
    Draw.schedule(() => {
      store.dispatch('piano-state/autoPianoKeysPress', { keys: [note], dur: timeInterval })
    }, time)
  }, timeInterval)
  partsHandler.addPart('pitchPractice', pitchExerciseLoop)
  partsHandler.togglePart('pitchPractice')
}

export function scheduleExercise({ startNote, endNote, steps = [2], exercise, isInteractive }) {
  const playCallbacks = [
    UPDATE_CAPTURE_USER_MEDIA_STATE,
    PLAY_INTERVALS_CALLBACK,
    STOP_PART_CALLBACK
  ]

  const callback = (time, event) => {
    const callbackPayload = {
      ...event,
      time
    }
    playCallbacks.forEach(cb => partCallbacks[cb](callbackPayload))
  }

  const events = exerciseToTonePartEvents(startNote, endNote, steps, exercise, isInteractive)
  concatStopTransportEvent(events)
  // @todo instead of attaching the play tracker to each event, make it global
  events.forEach(event => event.playTracker = playTracker)

  const part = new Part(callback, events)
  partsHandler.addPart('exercise', part)
  partsHandler.togglePart('exercise')
}

export function scheduleIntervals(startNote, intervals) {
  const callback = (time, event) => {
    partCallbacks[PLAY_INTERVALS_CALLBACK]({ ...event, time })
    partCallbacks[STOP_PART_CALLBACK](event)
  }
  const events   = intervalsToEvents(startNote, intervals, '4n')
  concatStopTransportEvent(events)
  const part = new Part(callback, events)
  partsHandler.addPart('exercise', part)
  partsHandler.togglePart('exercise')
}
