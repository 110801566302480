<template>
  <v-row>
    <v-col :cols="12">
      Please verify your email to use all the app's features.
    </v-col>
    <v-col :cols="12">
      <v-btn block color="success" @click="verify">click here to verify</v-btn>
    </v-col>
    <v-col :cols="12">
      <v-btn block color="secondary" @click="reload">Check if verified</v-btn>
    </v-col>
  </v-row>
</template>

<script>
  import {sendVerificationEmail, checkVerified} from 'framework/environment/auth'

  export default {
    name: 'NotVerifiedUser',

    methods: {
      verify() {
        sendVerificationEmail()
      },

      reload() {
        checkVerified()
      },
    },
  }
</script>


