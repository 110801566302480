<template>
  <div>
    <v-row>
      <v-col v-for="(exercise, i) in exercises" :key="i">
        <v-btn :x-small="$vuetify.breakpoint.mdAndDown"
               class="text-capitalize"
               :color="exerciseColor(exercise)"
               @click="selected=exercise">
          {{ exercise.name }}
        </v-btn>
      </v-col>
    </v-row>
    <div v-if="selected" class="elevation-2 pa-4">
      <div class="font-weight-medium title">
        {{ selected.name }}
      </div>
      <div class="body-1">
        Pre Harmony: {{ selected.preHarmony.join(', ') }}
        <br>
        Intervals: {{ selected.intervals.join(', ') }}
      </div>
      <v-row justify="center" align="center">
        <v-col>
          <v-btn block :small="$vuetify.breakpoint.smAndDown"
                 class="text-capitalize body-2"
                 :color="getIsTransportStarted ? 'error' : 'success'"
                 @click="play">
            <app-icon :icon="getIsTransportStarted ? 'stop' : 'play-arrow'" :size="20"/>
          </v-btn>
        </v-col>
        <v-col>
          <v-btn block :small="$vuetify.breakpoint.smAndDown"
                 class="text-capitalize body-2"
                 color="error"
                 @click="deleteExercise">
            <app-icon icon="delete"/>
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { scheduleIntervals }      from 'src/utilities/player-helpers'

export default {
  name:     'UserExercises',
  mounted() {
    this.updateUserExercises()
  },
  data() {
    return {
      selected:  null,
      exercises: [],
    }
  },
  computed: {
    ...mapGetters('user', ['getExercises']),
    ...mapGetters('context', ['getDefaultNote']),
    ...mapGetters('transport', ['getIsTransportStarted']),
  },
  methods:  {
    ...mapActions('transport', ['startPlayback', 'stopPlayback']),
    async updateUserExercises() {
      const exercises = await this.getExercises?.readAll()
      this.exercises  = exercises
      console.log(exercises)
    },

    exerciseColor(exercise) {
      const name = this.selected?.name
      return exercise.name === name ? 'secondary' : 'primary darken-2'
    },

    play() {
      if(this.getIsTransportStarted) {
        this.stopPlayback()
      } else {
        const preHarmonyIntervals = this.selected.preHarmony
        const intervals           = this.selected.intervals
        scheduleIntervals(this.getDefaultNote, [preHarmonyIntervals, ...intervals])
        this.startPlayback()
      }
    },

    deleteExercise() {
      this.getExercises.delete(this.selected.id)
      this.updateUserExercises()
      this.selected = null
    },
  },
}
</script>

<style scoped>

</style>