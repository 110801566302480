import { calculateNotesDistance, transposeNote } from 'framework/helpers/note-art-helpers'
import { fitArrayToSize }                        from 'note-art'
import { toArray }                               from 'framework/utilities/Utilities'

/**
 * Generates an events object for a exercise to be called with a Tone.Part.
 * @param startNote {String} Note to start the exercise from.
 * @param endNote {String} Note to end the exercise on.
 * @param steps {Array} Array of semi tones for the exercise to be built based on. e.g Chromatic, major, etc...
 * @param exercise {Array} Array of semi notes for the exercise to use.
 * @param isInteractive {boolean} Whether to schedule rests of each exercise for the user to sing the notes solo.
 * @returns {[]}
 */
export function exerciseToTonePartEvents(startNote, endNote, steps, exercise, isInteractive) {
  const overallTime    = { '4n': 0, '2n': 0 }
  const distance       = calculateNotesDistance(startNote, endNote)
  const exercisesSteps = fitArrayToSize(steps, distance + 1)
  const events         = []
  events.push(...exerciseSectionToTonePartEvents(startNote, exercise, overallTime))
  if(isInteractive) {
    events.push(...interactiveSectionToTonePartEvents(startNote, exercise, overallTime))
  }
  let interval = 0, stepIndex = 0, isDescending = false
  while(stepIndex >= 0) {
    interval = exercisesSteps[stepIndex]
    if(interval >= distance) {
      isDescending = true
    }
    const currNote = transposeNote(startNote, interval)
    events.push(...exerciseSectionToTonePartEvents(currNote, exercise, overallTime))
    isDescending ? --stepIndex : ++stepIndex
    if(isInteractive) {
      events.push(...interactiveSectionToTonePartEvents(currNote, exercise, overallTime))
    }
  }
  events.push(...exerciseSectionToTonePartEvents(startNote, exercise, overallTime))
  if(isInteractive) {
    events.push(...interactiveSectionToTonePartEvents(startNote, exercise, overallTime))
  }
  return events
}

/**
 * Returns the events object for a single exercise section.
 * @param note {String}
 * @param exercise {Object}
 * @param overallTime {Object}
 * @returns {[{dur: string, note: *, intervals: *, time: *}]}
 */
function exerciseSectionToTonePartEvents(note, exercise, overallTime) {
  const events = [{ note, intervals: exercise.preHarmony, dur: '2n', time: { ...overallTime } }]
  ++overallTime['2n']
  events.push(...intervalsToEvents(note, exercise.intervals, '4n', overallTime))
  events.push({ note, intervals: exercise.preHarmony, dur: '4n', time: { ...overallTime } })
  ++overallTime['4n']
  return events
}

/**
 * Returns the events object for playing an array of intervals from some base note with a fixed duration interval.
 * @param note base note
 * @param intervals Array of intervals
 * @param dur duration to play each interval for
 * @param overallTime Optional, previous time object to add upon
 * @returns {[{time: {}}]}
 */
export function intervalsToEvents(note, intervals, dur, overallTime) {
  overallTime = overallTime || { [dur]: 0 }
  return intervals.map(interval => {
    const intervals = toArray(interval)
    const event = { note, intervals, dur, time: { ...overallTime } }
    overallTime[dur]++
    return event
  })
}

/**
 * Returns the events object for an interactive section.
 * @param note {String}
 * @param exercise {Object}
 * @param overallTime {Object}
 * @returns {[{dur: string, note: *, intervals: *, time: *, captureUserMedia: boolean}]}
 */
function interactiveSectionToTonePartEvents(note, exercise, overallTime) {
  const events = [{ captureUserMedia: true, note, intervals: exercise.preHarmony, dur: '2n', time: { ...overallTime } }]
  ++overallTime['2n']
  for(let i = 0; i < exercise.intervals.length; ++i) {
    events.push({ dur: '4n', time: { ...overallTime } })
    ++overallTime['4n']
  }
  events.push({ captureUserMedia: false, note, intervals: exercise.preHarmony, dur: '4n', time: { ...overallTime } })
  ++overallTime['4n']
  return events
}