<template>
  <app-dialog @close="$emit('close')" :padding="0">
    <template #title>Logout</template>
    <div v-if="!loggedOutSuccess" class="text-center">
      <div class="pa-5 title font-weight-bold">
        Are you sure? 😲
      </div>
      <div class="d-flex justify-center">
        <div class="pa-2 d-inline-flex flex-fill">
          <v-btn small rounded block color="success darken-2" @click="$emit('close')">
            No
          </v-btn>
        </div>
        <div class="pa-2 d-inline-flex flex-fill">
          <v-btn small rounded block color="error darken-2" @click="logoutUser">
            Yes
          </v-btn>
        </div>
      </div>
    </div>
    <v-row v-else no-gutters justify="center" align="center">
      <v-col :cols="12" class="pa-4 text-center">
        Success!<br> You have been logged out.
      </v-col>
      <v-col :cols="12">
        <v-btn block rounded small color="success" @click="$emit('close')">
          Ok
        </v-btn>
      </v-col>
    </v-row>
  </app-dialog>
</template>

<script>
  import { mapActions } from 'vuex'
  import 'firebase/auth'
  import firebase  from 'firebase/app'

  export default {
    name: 'Logout',

    components: {
      AppDialog: () => import(/* webpackChunkName: "app-main" */ 'framework/ui/components/AppDialog'),
    },

    data() {
      return {
        loggedOutSuccess: false,
      }
    },

    methods: {
      ...mapActions('user', ['logout']),

      async logoutUser() {
        await firebase.auth().signOut()
        await this.logout()
        this.loggedOutSuccess = true
        this.$router.push('/')
      },
    },
  }
</script>
